.module-footer {
}

.module-footer .footer-container {
    display: grid;
    gap: 1rem 2rem;
    margin: 0 auto;
    max-width: var(--width-main);
    padding: 2.5rem var(--block-space-horizontal);
}

.module-footer .logo img {
    width: 10rem;
}

.module-footer h4 {
    margin: 1rem 0;
    color: var(--color-grey)
}

.module-footer address {
    display: grid;
    font-style: normal;
    grid-template-rows: auto 1fr 1rem;
}

.module-footer address a {
    align-self: end;
}

.module-footer a {
    font-weight: bold;
    text-decoration: none;
}

.module-footer a:hover {
    color: var(--color-yellow)
}

.module-footer a.button:hover {
    color: var(--color-light)
}

.module-footer .side {
    display: grid;
    font-style: normal;
    grid-template-rows: 50% 50%;
}

.module-footer .side img {
    display: inline;
    width: 1rem;
}

@media (min-width: 480px) {
    .module-footer address {
        grid-template-rows: auto 1fr 1rem;
    }

    .module-footer .navigation {
        padding-bottom: 1.5rem;
    }

    .module-footer .address {
        order:1
    }

    .module-footer .side {
        order:2
    }

    .module-footer .footer-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .module-footer .logo {
        grid-column: 1 / span 2;
    }
}

@media (min-width: 1080px) {
    .module-footer .address {
        order:inherit
    }

    .module-footer .side {
        order:inherit
    }

    .module-footer address {
        grid-template-rows: auto 1fr 3rem;
    }

    .module-footer .footer-container {
        grid-template-columns: repeat(4, 1fr);
        padding: var(--block-space-horizontal);
    }

    .module-footer .logo {
        grid-column: 1 / span 4;
    }
}

